import React, { ReactElement } from 'react';
import { Cell } from 'react-table';
import { Flex, Tooltip } from '@chakra-ui/react';
import { Barcode, CopySimple } from 'phosphor-react';
import { Box } from '@chakra-ui/layout';
import { ActionButtonColumnProps } from '@/components/paginated-table/types';

export const QuickActionsCell = ({ value, row, column }: Cell): ReactElement => {
  const { getProps } = column as ActionButtonColumnProps;
  return (
    <Flex px="1">
      <Tooltip label="View QR Code" hasArrow placement="top" bg="gray.700" color="white">
        <Box
          color="blue.700"
          px="1"
          onClick={() => {
            getProps?.onActionIconClick?.(row, 'SHOW_QRCODE');
          }}
          cursor="pointer"
        >
          <Barcode size="24" />
        </Box>
      </Tooltip>
      <Tooltip label="Copy QuickLink" hasArrow placement="top" bg="gray.700" color="white">
        <Box
          color="blue.700"
          onClick={() => {
            getProps?.onActionIconClick?.(row, 'COPY');
          }}
          cursor="pointer"
        >
          <CopySimple size="24" />
        </Box>
      </Tooltip>
    </Flex>
  );
};
