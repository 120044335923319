import React from 'react';
import QRCode from 'react-qr-code';
import { Box } from '@chakra-ui/react';

interface QRCodeProps {
  url: string;
}

const QrCode: React.FC<QRCodeProps> = ({ url }) => {
  return (
    <Box bg="white" borderRadius="md" display="inline-block">
      <QRCode
        value={url || ' '}
        size={175}
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        viewBox="0 0 256 256"
      />
    </Box>
  );
};

export default QrCode;
