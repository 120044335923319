/* eslint-disable no-nested-ternary */
import React, { ReactElement } from 'react';
import { Button, Tooltip } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { Globe } from 'phosphor-react';
import { Text } from '@chakra-ui/layout';
import { SelectedNodeAtom } from '../states/selectedNodeAtom';

const QuickLinkButton = ({
  openConfigureModal,
  workflowId,
}: {
  openConfigureModal: () => void;
  workflowId: string;
}): ReactElement => {
  const setSelectedNode = useSetRecoilState(SelectedNodeAtom);

  return (
    <Tooltip
      label={
        <Text fontSize="xs" fontWeight="normal" color="gray.50">
          {// eslint-disable-next-line no-nested-ternary
          !workflowId && 'Please publish Workflow first.'}
        </Text>
      }
      aria-label="A tooltip for quicklink button"
      isDisabled={!!workflowId}
      hasArrow
      placement="bottom-start"
      openDelay={200}
      closeDelay={300}
      p="3"
      borderRadius="lg"
      bg="gray.800"
      maxW="52"
      shouldWrapChildren
    >
      <Button
        fontWeight="normal"
        type="submit"
        gridGap="2"
        borderWidth={1}
        color="gray.800"
        borderRadius="lg"
        fontSize="sm"
        bg="white.50"
        borderColor="gray.200"
        onClick={() => {
          openConfigureModal();
          setSelectedNode({ id: 'quicklink', type: 'quicklink', columns: [] });
        }}
        isDisabled={!workflowId}
        _disabled={{ cursor: 'pointer', opacity: 0.5 }}
      >
        <Globe size={18} weight="fill" color="black" /> Quicklink
      </Button>
    </Tooltip>
  );
};

export default QuickLinkButton;
