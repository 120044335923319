/* eslint-disable no-nested-ternary */
import React, { ReactElement } from 'react';
import { Button } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { CodeSimple } from 'phosphor-react';
import { SelectedNodeAtom } from './states/selectedNodeAtom';

const IntegrationsButton = ({
  openConfigureModal,
  workflowId,
}: {
  openConfigureModal: () => void;
  workflowId: string;
}): ReactElement => {
  const setSelectedNode = useSetRecoilState(SelectedNodeAtom);

  return (
    <Button
      borderRadius="lg"
      fontSize="sm"
      fontWeight="normal"
      bg="white.50"
      borderColor="gray.200"
      type="submit"
      gridGap="2"
      borderWidth={1}
      color="gray.800"
      id="integration-workflow"
      onClick={() => {
        openConfigureModal();
        setSelectedNode({ id: 'integrations', type: 'integrations', columns: [] });
      }}
      isDisabled={!workflowId}
    >
      <CodeSimple size={18} weight="light" color="black" /> Integrate
    </Button>
  );
};

export default IntegrationsButton;
