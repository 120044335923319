import React, { Dispatch, SetStateAction } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import SearchInput from '@/components/search-input/SearchInput';
import DateSelectBox from '@/components/date-select-box/DateSelectBox';
import { useResetRecoilState } from 'recoil';
import { Globe, ListBullets, Plus } from 'phosphor-react';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { WorkflowFilterField } from '../types';
import { WorkflowAtom } from '../states/workflowAtom';
import { BranchAtom } from '../states/branchAtom';
import { EndnodeAtom } from '../states/endnodeAtom';
import { SelectedNodeAtom } from '../states/selectedNodeAtom';
import { ConditionsAtom } from '../states/conditionsAtom';
import { WorkflowNameAtom } from '../states/workflowName';

const WorkflowSearchFilter = ({
  filterFields,
  setSearchTerm,
  setFilterFields,
  workflowType,
}: {
  filterFields: WorkflowFilterField;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setFilterFields: Dispatch<SetStateAction<WorkflowFilterField>>;
  workflowType: string;
}): React.ReactElement => {
  const handleDateFilterChange = (startDate: string, endDate: string): void => {
    setFilterFields({ ...filterFields, createdAt: [startDate, endDate] });
  };

  const history = useHistory();
  const queryClient = useQueryClient();

  const resetWorkflow = useResetRecoilState(WorkflowAtom);
  const resetBranch = useResetRecoilState(BranchAtom);
  const resetConditions = useResetRecoilState(ConditionsAtom);
  const resetEndNode = useResetRecoilState(EndnodeAtom);
  const resetSelectedNodeItem = useResetRecoilState(SelectedNodeAtom);
  const resetWorkflowName = useResetRecoilState(WorkflowNameAtom);

  const onCreateWorkflow = (): void => {
    resetWorkflow();
    resetBranch();
    resetConditions();
    resetEndNode();
    resetSelectedNodeItem();
    resetWorkflowName();
    queryClient.removeQueries({ queryKey: 'capabilityList', exact: true });
    history.push(`/workflows/create`);
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      {workflowType !== 'templates' ? <SearchInput setSearchTerm={setSearchTerm} id="search-workflows" /> : <Flex />}

      <Flex alignItems="center" gridGap="2">
        {workflowType !== 'templates' ? (
          <DateSelectBox
            selectedDates={filterFields.createdAt || []}
            onChange={handleDateFilterChange}
            menuHeading="Select time"
          >
            <Text fontWeight={300} color="gray.600" fontSize="xs" id="workflow-date">
              Created Date:
            </Text>
          </DateSelectBox>
        ) : (
          <Flex h="full" />
        )}

        <Button
          leftIcon={<Globe weight="fill" />}
          backgroundColor="gray.200"
          borderRadius="lg"
          fontSize="sm"
          fontWeight="light"
          onClick={() => history.push('/quicklinks')}
          id="quicklinks"
          mx="1"
          color="gray.800"
        >
          QuickLinks
        </Button>
        <Button
          leftIcon={<ListBullets />}
          backgroundColor="gray.200"
          borderRadius="lg"
          fontSize="sm"
          fontWeight="light"
          onClick={() => history.push('/blocklists')}
          id="create-workflow"
          mx="1"
          color="gray.800"
        >
          Lists
        </Button>
        <Button
          leftIcon={<Plus />}
          bg="purple.300"
          fontSize="sm"
          fontWeight="light"
          color="blue.500"
          borderRadius="lg"
          lineHeight="normal"
          onClick={onCreateWorkflow}
          id="create-workflow"
          mx="1"
        >
          New Workflow
        </Button>
      </Flex>
    </Flex>
  );
};

export default WorkflowSearchFilter;
