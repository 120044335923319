import React, { ReactElement, useState } from 'react';
import { Cell } from 'react-table';
import { Flex, Heading } from '@chakra-ui/react';
import { Trash } from 'phosphor-react';
import { Box } from '@chakra-ui/layout';
import { ActionButtonColumnProps } from '@/components/paginated-table/types';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal';

export const DeleteQuickLinkCell = ({ value, row, column }: Cell): ReactElement => {
  const { getProps } = column as ActionButtonColumnProps;
  const [show, setShow] = useState(false);
  return (
    <Flex px="1">
      <Box
        px="1"
        onClick={() => {
          setShow(true);
        }}
        cursor="pointer"
      >
        <Trash size="24" />
      </Box>
      {show ? (
        <ConfirmationModal
          headerText={`Are you sure want to delete ${row.values.name}?`}
          bodyText={
            <Heading as="h6" fontSize="14px" fontWeight="light">
              You will not be able to bring the changes you made.
            </Heading>
          }
          onClose={() => getProps?.onActionIconClick?.(row, 'DELETE')}
          onCancel={() => setShow(false)}
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          primaryButtonBg="red"
        />
      ) : null}
    </Flex>
  );
};
