import React, { ChangeEvent, Dispatch, ReactElement, SetStateAction, useState } from 'react';
import FactsLoader from '@/components/loader/Loader';
import {
  Box,
  Button,
  Flex,
  Text,
  PopoverContent,
  Popover,
  PopoverBody,
  PopoverHeader,
  PopoverCloseButton,
  PopoverFooter,
  useToast,
  Input,
} from '@chakra-ui/react';
import { colors } from '@/utils/colors';
import { CodeSimple, Files, Globe, PencilSimple, Play } from 'phosphor-react';

import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { bannerHeight } from '@/constants/constants';
import useGetInputAttributes from '../workflows/workflow-creation/queries/useGetInputAttributes';
import { WorkflowAtom } from './states/workflowAtom';
import { SelectedNodeAtom } from './states/selectedNodeAtom';
import SourceConfiguration from './SourceConfiguration';
import BranchConfig from './BranchingConfig';
import { ColumnV2, ConditionsType } from './types';
import { CONDITIONS_DATA, OUTPUTS } from './constant';
import { BranchAtom } from './states/branchAtom';
import { ConditionsAtom } from './states/conditionsAtom';
import { generateUUID } from './utils';
import ConditionsWrapper from './ConditionsWrapper';
import { EndnodeAtom } from './states/endnodeAtom';
import { WhenCondition } from '../workflows/workflow-creation/components/workflow-properties/types';
import { IntegrationConfig } from './IntegrationConfig';
import AlertList from './AlertList';
import TryoutConfig from './Tryout';
import CurlCopyButton from './components/CurlCopyButton';
import AsyncTag from './components/AsyncTag';
import SDKPreview from './SDKPreview';
import ThemeEventHandlers from './ThemeEventHandlers';
import { generateAwaitNodeValue } from './utils/utils';
import QuickLink from './quick-link/QuickLink';

function InputConfiguration({
  setIsCapabilityListOpen,
  onClose,
  isOpen,
  selectedCapability,
  workflowId,
}: {
  setIsCapabilityListOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  isOpen: boolean;
  selectedCapability: string;
  workflowId: string;
}): ReactElement {
  const { isLoading } = useGetInputAttributes();
  const resetNode = useResetRecoilState(SelectedNodeAtom);
  const resetConditions = useResetRecoilState(ConditionsAtom);
  const resetEndNode = useResetRecoilState(EndnodeAtom);
  const selectedNode = useRecoilValue(SelectedNodeAtom);
  const workflow = useRecoilValue(WorkflowAtom);
  const setWorkflow = useSetRecoilState(WorkflowAtom);
  const selectedBranch = useRecoilValue(BranchAtom);
  const conditionsBlock = useRecoilValue(ConditionsAtom);
  const endColumn = useRecoilValue(EndnodeAtom);
  const setEndColumn = useSetRecoilState(EndnodeAtom);
  const toast = useToast();
  const [openCustomiseTheme, setOpenCustomiseTheme] = useState(false);

  function checkEmptyCondition(condition: WhenCondition[]): boolean {
    const currentCondition = JSON.stringify(condition);
    return currentCondition.indexOf('obj') === -1;
  }

  function handleSaveSettings(): void {
    if (selectedNode.type === 'end') {
      let endNode = JSON.parse(JSON.stringify(endColumn)) as ColumnV2;
      endNode.nodes = [];
      if (conditionsBlock[ConditionsType.accept].length) {
        const isEmptyCondition = checkEmptyCondition(conditionsBlock[ConditionsType.accept]);
        if (!isEmptyCondition) {
          const nodeId = `accept_${generateUUID()}`;
          const outputs = JSON.parse(JSON.stringify(OUTPUTS).replace('namespace_id', nodeId));
          const acceptNode = {
            id: nodeId,
            kind: 'end_accepted',
            name: 'accept',
            outputs,
            conditions: conditionsBlock[ConditionsType.accept],
            dependencies: ['start_ghdvng'],
            inputs: [],
          };
          endNode.nodes.push(acceptNode);
        }
      } else {
        const isEndNodePresent = endNode.nodes.findIndex(node => node.kind === 'end_accepted') !== -1;
        if (isEndNodePresent) {
          endNode = {
            ...endNode,
            nodes: endNode.nodes.filter(node => node.kind !== 'end_accepted'),
          };
        }
      }
      if (conditionsBlock[ConditionsType.reject].length) {
        const isEmptyCondition = checkEmptyCondition(conditionsBlock[ConditionsType.reject]);
        if (!isEmptyCondition) {
          const nodeId = `reject_${generateUUID()}`;
          const outputs = JSON.parse(JSON.stringify(OUTPUTS).replace('namespace_id', nodeId));
          const rejectedNode = {
            id: nodeId,
            kind: 'end_rejected',
            name: 'rejected',
            outputs,
            conditions: conditionsBlock[ConditionsType.reject],
            dependencies: ['start_ghdvng'],
            inputs: [],
          };
          endNode.nodes.push(rejectedNode);
        }
      } else {
        const isEndNodePresent = endNode.nodes.findIndex(node => node.kind === 'end_rejected') !== -1;
        if (isEndNodePresent) {
          endNode = {
            ...endNode,
            nodes: endNode.nodes.filter(node => node.kind !== 'end_rejected'),
          };
        }
      }
      if (conditionsBlock[ConditionsType.manual_review].length) {
        const isEmptyCondition = checkEmptyCondition(conditionsBlock[ConditionsType.manual_review]);
        if (!isEmptyCondition) {
          const nodeId = `manual_review_${generateUUID()}`;
          const outputs = JSON.parse(JSON.stringify(OUTPUTS).replace('namespace_id', nodeId));
          const manual_revieNode = {
            id: nodeId,
            kind: 'end_manual_review',
            name: 'manual_review',
            outputs,
            conditions: conditionsBlock[ConditionsType.manual_review],
            dependencies: ['start_ghdvng'],
            inputs: [],
          };
          endNode.nodes.push(manual_revieNode);
        }
      } else {
        const isEndNodePresent = endNode.nodes.findIndex(node => node.kind === 'end_manual_review') !== -1;
        if (isEndNodePresent) {
          endNode = {
            ...endNode,
            nodes: endNode.nodes.filter(node => node.kind !== 'end_manual_review'),
          };
        }
      }
      if (endNode.nodes.length) {
        setEndColumn(endNode);
      } else {
        resetEndNode();
      }

      toast({
        title: 'Settings saved successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    let newWorkflow = [] as ColumnV2[];
    if (selectedNode.type === 'source') {
      const selectedColumns = selectedNode.columns.reduce(
        (acc, column) => ({
          ...acc,
          [column.id]: column,
        }),
        {},
      ) as { [key: string]: ColumnV2 };
      const selectColumnIds = Object.keys(selectedColumns);
      newWorkflow = JSON.parse(JSON.stringify(workflow)) as ColumnV2[];
      newWorkflow = newWorkflow.map(column => {
        if (selectColumnIds.indexOf(column.id) > -1) {
          return selectedColumns[column.id];
        }
        return column;
      });
    } else if (selectedNode.type === 'branch') {
      const successConditionTag = `tag.${selectedNode.id}.status`;
      const successCondition = JSON.stringify(CONDITIONS_DATA)
        .replace('tag.gerua_887b25acb9eb401b95e486c3fc123061.status', successConditionTag)
        .replace('Success', 'OK');
      const failureCondition = JSON.stringify(CONDITIONS_DATA)
        .replace('tag.gerua_887b25acb9eb401b95e486c3fc123061.status', successConditionTag)
        .replace('Success', 'FAILED');
      let clonedWorkflow = JSON.parse(JSON.stringify(workflow)) as ColumnV2[];
      const currentIndex = clonedWorkflow.findIndex(column => column.metadata.capabilityId === selectedNode.id);
      clonedWorkflow = clonedWorkflow.map(column => {
        if (column.nodes[0].kind === 'AWAIT') {
          return column;
        }
        if (column.metadata.branch === 'Branch A' && column.metadata.parentID === selectedNode.id) {
          const condition = selectedBranch === 'branch A' ? JSON.parse(successCondition) : JSON.parse(failureCondition);
          const tempColumn = JSON.parse(JSON.stringify(column)) as ColumnV2;
          tempColumn.nodes[0].conditions = condition;
          return {
            ...tempColumn,
            nodes: tempColumn.nodes.map(node => ({
              ...node,
              conditions: condition,
            })),
          };
        }
        if (column.metadata.branch === 'Branch B' && column.metadata.parentID === selectedNode.id) {
          const condition = selectedBranch === 'branch B' ? JSON.parse(successCondition) : JSON.parse(failureCondition);
          const tempColumn = JSON.parse(JSON.stringify(column)) as ColumnV2;
          tempColumn.nodes[0].conditions = condition;
          return {
            ...tempColumn,
            nodes: tempColumn.nodes.map(node => ({
              ...node,
              conditions: condition,
            })),
          };
        }
        return column;
      });
      if (currentIndex !== -1) {
        clonedWorkflow[currentIndex].metadata.branch = selectedBranch;
      }

      if (currentIndex !== -1) {
        const isEmptyCondition = checkEmptyCondition(conditionsBlock[ConditionsType.accept]);
        if (!isEmptyCondition) {
          clonedWorkflow[currentIndex].nodes[0].conditions = conditionsBlock[ConditionsType.accept];
        }
      }
      newWorkflow = clonedWorkflow;
    }
    if (selectedNode.type === 'source' || selectedNode.type === 'branch') {
      const awaitNode = generateAwaitNodeValue(selectedNode.columns);
      newWorkflow = newWorkflow.map(column => {
        if (column.metadata.capabilityId === selectedNode.id && column.nodes[0].kind === 'AWAIT') {
          return {
            ...awaitNode,
            metadata: {
              capabilityId: selectedNode.id || '',
              capabilityName: selectedNode.columns[0].metadata.capabilityName,
              kind: 'source',
              parentID: selectedNode.columns[0].metadata.parentID,
              branch: 'source',
              description: selectedNode.columns[0].metadata.description,
              isAsync: false,
            },
          };
        }
        return column;
      });
    }
    const parentIndex = workflow.findIndex(
      column =>
        column.metadata.parentID === selectedNode.columns[0].metadata.capabilityId &&
        column.metadata.kind === 'condition',
    );
    const newColumn = {
      id: `column_${generateUUID()}`,
      nodes: [],
      metadata: {
        parentID: selectedNode.type === 'source' ? selectedNode.columns[0].metadata.capabilityId : '',
        kind: 'condition',
      },
    } as ColumnV2;
    if (conditionsBlock[ConditionsType.accept].length) {
      const isEmptyCondition = checkEmptyCondition(conditionsBlock[ConditionsType.accept]);
      if (!isEmptyCondition) {
        const nodeId = `accept_${generateUUID()}`;
        const outputs = JSON.parse(JSON.stringify(OUTPUTS).replace('namespace_id', nodeId));
        const acceptNode = {
          id: nodeId,
          kind: 'end_accepted',
          name: 'accept',
          outputs,
          conditions: conditionsBlock[ConditionsType.accept],
          dependencies: ['start_ghdvng'],
          inputs: [],
        };
        newColumn.nodes.push(acceptNode);
      }
    } else if (parentIndex !== -1) {
      newColumn.nodes = newColumn.nodes.filter(node => node.kind !== 'end_accepted');
    }
    if (conditionsBlock[ConditionsType.reject].length) {
      const isEmptyCondition = checkEmptyCondition(conditionsBlock[ConditionsType.reject]);
      if (!isEmptyCondition) {
        const nodeId = `reject_${generateUUID()}`;
        const outputs = JSON.parse(JSON.stringify(OUTPUTS).replace('namespace_id', nodeId));
        const rejectedNode = {
          id: nodeId,
          kind: 'end_rejected',
          name: 'rejected',
          outputs,
          conditions: conditionsBlock[ConditionsType.reject],
          dependencies: ['start_ghdvng'],
          inputs: [],
        };
        newColumn.nodes.push(rejectedNode);
      }
    } else if (parentIndex !== -1) {
      newColumn.nodes = newColumn.nodes.filter(node => node.kind !== 'end_rejected');
    }
    if (conditionsBlock[ConditionsType.manual_review].length) {
      const isEmptyCondition = checkEmptyCondition(conditionsBlock[ConditionsType.manual_review]);
      if (!isEmptyCondition) {
        const nodeId = `manual_review_${generateUUID()}`;
        const outputs = JSON.parse(JSON.stringify(OUTPUTS).replace('namespace_id', nodeId));
        const manual_revieNode = {
          id: nodeId,
          kind: 'end_manual_review',
          name: 'manual_review',
          outputs,
          conditions: conditionsBlock[ConditionsType.manual_review],
          dependencies: ['start_ghdvng'],
          inputs: [],
        };
        newColumn.nodes.push(manual_revieNode);
      }
    } else if (parentIndex !== -1) {
      newColumn.nodes = newColumn.nodes.filter(node => node.kind !== 'end_manual_review');
    }

    const indexedWorkflow = workflow.map((w, index) => ({ ...w, index }));
    const currentColumnIndex = indexedWorkflow
      .filter(wrkflw => wrkflw.metadata.capabilityId === selectedNode.id)
      .slice(-1)[0].index;
    if (selectedNode.type === 'source' && newColumn.nodes.length) {
      if (parentIndex === -1) {
        newWorkflow = [
          ...newWorkflow.slice(0, currentColumnIndex + 1),
          newColumn,
          ...newWorkflow.slice(currentColumnIndex + 1),
        ];
      } else {
        newWorkflow[parentIndex] = newColumn;
      }
    } else if (selectedNode.type === 'source') {
      newWorkflow = newWorkflow.filter((w, i) => i !== parentIndex);
    }
    setWorkflow(newWorkflow);
    toast({
      title: 'Settings saved successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }

  function renderConfiguration(): ReactElement {
    if (selectedNode.type === 'preview') {
      return <SDKPreview openCustomiseTheme={openCustomiseTheme} workflowId={workflowId} />;
    }
    if (selectedNode.type === 'alerts') {
      return <AlertList />;
    }
    if (selectedNode.type === 'source') {
      return <SourceConfiguration />;
    }

    if (selectedNode.type === 'branch') {
      return <BranchConfig />;
    }

    if (selectedNode.type === 'integrations') {
      return <IntegrationConfig workflowId={workflowId} />;
    }

    if (selectedNode.type === 'tryout') {
      return <TryoutConfig workflowId={workflowId} />;
    }

    if (selectedNode.type === 'quicklink') {
      return <QuickLink workflowId={workflowId} />;
    }

    return <ConditionsWrapper />;
  }

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setIsCapabilityListOpen(true);
        resetNode();
        resetConditions();
        setOpenCustomiseTheme(false);
      }}
      closeOnBlur={false}
      preventOverflow
    >
      <PopoverContent
        w={selectedNode.type === 'preview' && openCustomiseTheme ? 'auto' : 'xs'}
        bg="white"
        borderRadius="lg"
        minW="52vw"
        h={`calc(100vh - ${bannerHeight + 115}px)`}
        zIndex="0"
        position="fixed"
        right="6"
        bottom="6"
        _focus={{ boxShadow: 'none', outline: 'none' }}
      >
        {isLoading ? (
          <FactsLoader />
        ) : (
          <>
            {selectedNode.id ? (
              <>
                <InputConfigurationHeader
                  capability={selectedCapability}
                  workflowId={workflowId}
                  openCustomiseTheme={openCustomiseTheme}
                  setOpenCustomiseTheme={setOpenCustomiseTheme}
                />
                <PopoverBody p={0} overflow="auto" h="full" borderRadius="lg">
                  {renderConfiguration()}
                </PopoverBody>
                {!['alerts', 'integrations', 'tryout', 'preview', 'quicklink'].includes(selectedNode.type) ? (
                  <PopoverFooter borderTopWidth={1} borderColor="gray.300" p="4">
                    <Button
                      colorScheme="blue"
                      fontSize="sm"
                      fontWeight="light"
                      borderRadius="lg"
                      _hover={{ opacity: '0.85' }}
                      _disabled={{ bg: 'gray.100', color: 'gray.600' }}
                      onClick={handleSaveSettings}
                    >
                      Save Settings
                    </Button>
                  </PopoverFooter>
                ) : null}
              </>
            ) : null}
          </>
        )}
      </PopoverContent>
    </Popover>
  );
}

const InputConfigurationHeader = ({
  capability,
  workflowId,
  openCustomiseTheme,
  setOpenCustomiseTheme,
}: {
  capability: string;
  workflowId: string;
  openCustomiseTheme: boolean;
  setOpenCustomiseTheme: Dispatch<SetStateAction<boolean>>;
}): ReactElement => {
  const selectedNode = useRecoilValue(SelectedNodeAtom);
  const workflows = useRecoilValue(WorkflowAtom);
  const setWorkflow = useSetRecoilState(WorkflowAtom);
  const [showEdit, setShowEdit] = useState(false);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getSelectedCapability = (): ColumnV2 => {
    if (selectedNode.type === 'end')
      return {
        id: '',
        metadata: {
          capabilityName: 'End Workflow',
          description:
            'Complete your workflow by selecting a decision as a response, one of the three possible End States: Accepted, Rejected, or Manual Review.',
        },
        nodes: [],
      };

    if (selectedNode.type === 'integrations') {
      return {
        id: '',
        metadata: {
          capabilityName: '',
          description: 'Integrate workflow guide.',
        },
        nodes: [],
      };
    }
    if (selectedNode.type === 'quicklink') {
      return {
        id: '',
        metadata: {
          capabilityName: '',
          description: '',
        },
        nodes: [],
      };
    }

    if (selectedNode.type === 'tryout')
      return {
        id: '',
        metadata: {
          capabilityName: 'Tryout Workflow',
          description: '',
        },
        nodes: [],
      };

    if (selectedNode.type === 'branch') {
      const selectedNodeIndex = workflows.findIndex(wrkFlw => wrkFlw.metadata.capabilityId === selectedNode.id);
      return {
        id: '',
        metadata: {
          capabilityName: workflows[selectedNodeIndex].nodes[0].name,
          description:
            "Describe the execution conditions for each branch. If Branch A executes, Branch B won't execute, vice versa",
        },
        nodes: [],
      };
    }

    return (
      workflows.find(workflow => workflow.metadata.capabilityId === capability) || {
        id: '',
        metadata: { capabilityName: '', description: '' },
        nodes: [],
      }
    );
  };

  function getIcon(): ReactElement {
    if (selectedNode.type === 'branch' || selectedNode.type === 'source') {
      return <Files size={16} color={colors.gray[800]} weight="thin" />;
    }

    if (selectedNode.type === 'tryout') {
      return <Play size={16} color={colors.gray[800]} weight="fill" />;
    }
    if (selectedNode.type === 'quicklink') {
      return <Globe size={16} color={colors.gray[800]} weight="fill" />;
    }

    return <CodeSimple size={16} color={colors.gray[800]} weight="thin" />;
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    const parsedWorkflow = JSON.parse(JSON.stringify(workflows)) as ColumnV2[];
    const selectedNodeIndex = parsedWorkflow.findIndex(wrkFlw => wrkFlw.id === selectedNode.id);
    parsedWorkflow[selectedNodeIndex].nodes[0].name = value;
    setWorkflow(parsedWorkflow);
  }

  function getDetails(): ReactElement {
    if (selectedNode.type === 'branch') {
      return (
        <Flex alignItems="center" gridGap={2} maxW="300px">
          {showEdit ? (
            <Input value={getSelectedCapability().metadata.capabilityName} onChange={handleChange} />
          ) : (
            <>{getSelectedCapability().metadata.capabilityName}</>
          )}
          <PencilSimple
            size={24}
            color={colors.gray[500]}
            onClick={() => {
              setShowEdit(edit => !edit);
            }}
          />
        </Flex>
      );
    }

    if (selectedNode.type === 'quicklink') {
      return <>QuickLink</>;
    }
    if (selectedNode.type === 'integrations') {
      return <>Integration</>;
    }

    if (selectedNode.type === 'tryout') {
      return <>Tryout Workflow</>;
    }

    if (selectedNode.type === 'alerts') {
      return <>Alerts</>;
    }
    if (selectedNode.type === 'tag') {
      return <>Condition</>;
    }
    return <>{getSelectedCapability().metadata.capabilityName || ''}</>;
  }

  if (selectedNode.type === 'preview') {
    return (
      <>
        <PopoverHeader p="4" borderColor="gray.300" borderBottomWidth={1}>
          <ThemeEventHandlers openCustomiseTheme={openCustomiseTheme} setOpenCustomiseTheme={setOpenCustomiseTheme} />
        </PopoverHeader>
        <PopoverCloseButton size="4" color="gray.400" top={4} _focus={{ border: 'none' }} />
      </>
    );
  }

  return (
    <>
      <PopoverHeader p="4" borderColor="gray.300" borderBottomWidth={1}>
        <Flex as="span" flex="1" textAlign="left" direction="column" gridGap="2">
          <Flex gridGap="2" alignItems="center">
            <Box p="2" bg="white.50" borderRadius="50%">
              {getIcon()}
            </Box>
            <Flex gridGap="2" alignItems="center" flexGrow={1}>
              <Text fontSize="lg" fontWeight="medium" color="gray.800" flexGrow={1}>
                {getDetails()}
              </Text>
              {getSelectedCapability().metadata?.isAsync && <AsyncTag />}
            </Flex>
            {selectedNode.type === 'tryout' && <CurlCopyButton workflowId={workflowId} />}
          </Flex>
          <Text fontSize="xs" fontWeight="300" color="gray.600" lineHeight="160%">
            {getSelectedCapability().metadata.description}
          </Text>
        </Flex>
      </PopoverHeader>
      <PopoverCloseButton size="4" color="gray.400" top={4} _focus={{ border: 'none' }} />
    </>
  );
};

export default InputConfiguration;
