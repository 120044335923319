/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  VStack,
  Text,
  Grid,
  Flex,
  Heading,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import { Formik, Form, FormikErrors, FormikTouched } from 'formik';
import { CopyButton } from '@bureau/components/src';
import { Barcode } from 'phosphor-react';
import { Divider } from '@chakra-ui/layout';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import QrCode from './QrCode';
import { QuickLinkData } from '../types';
import { useQuickLink } from './useQuickLink';
import CopyQuickLinkCreationCurl from '../components/CopyQuickLinkCreationCurl';

interface QuickLinkProps {
  workflowId: string;
}

interface QuickLinkIntegrationProps {
  workflowId: string;
}

interface QuickLinkFormProps {
  values: QuickLinkData;
  errors: FormikErrors<QuickLinkData>;
  touched: FormikTouched<QuickLinkData>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  isLoading: boolean;
}

interface QuickLinkPreviewProps {
  quicklinkUrl: string;
  trimmedUrl: string;
  navigateToQuickLink: () => void;
  documentationLink: string;
}
type CurlCommandDisplayProps = {};

const QuickLinkForm: React.FC<QuickLinkFormProps> = ({ values, errors, touched, handleChange, isLoading }) => {
  const hasErrors = Object.keys(errors).length > 0;
  const hasEmptyRequiredFields = !values.name || !values.transactionLimit;
  const isDisabled = hasErrors || hasEmptyRequiredFields;

  const showNameError = !!errors.name;
  const showTransactionLimitError = !!errors.transactionLimit;

  return (
    <VStack spacing={6} align="stretch">
      <Box borderWidth="1px" borderRadius="lg" p={6} bg="white" boxShadow="sm">
        <VStack spacing={4} align="stretch">
          <Text fontSize="16" fontWeight={400} mb={1}>
            Generate QuickLink and QR code for executing this workflow
          </Text>
          <FormControl isInvalid={showNameError}>
            <FormLabel fontSize="12" fontWeight="300">
              QuickLink Name
            </FormLabel>
            <Input name="name" value={values.name} onChange={handleChange} placeholder="Enter quicklink name" />
            {showNameError && <FormErrorMessage>{errors.name}</FormErrorMessage>}
          </FormControl>

          <FormControl isInvalid={showTransactionLimitError}>
            <Flex alignContent="center" alignItems="center">
              <FormLabel flex="5" fontSize="12" fontWeight="300">
                Link Validity (No. of Transactions)
              </FormLabel>
              <Input
                name="transactionLimit"
                type="number"
                value={values.transactionLimit}
                onChange={handleChange}
                flex="1"
              />
            </Flex>
            {showTransactionLimitError && <FormErrorMessage>{errors.transactionLimit}</FormErrorMessage>}
          </FormControl>

          <Button
            type="submit"
            bg="blue.500"
            color="white"
            width="fit-content"
            isLoading={isLoading}
            fontWeight="300"
            fontSize="14"
            isDisabled={isDisabled}
            _hover={{ bg: 'blue.600' }}
          >
            Generate Link
          </Button>
        </VStack>
      </Box>
    </VStack>
  );
};

export const QuickLinkIntegration: React.FC<QuickLinkIntegrationProps> = ({ workflowId }): JSX.Element => (
  <VStack spacing={6} mt="5" width="full">
    <Box borderWidth="1px" borderRadius="lg" p={6} bg="white" boxShadow="sm" width="full">
      <VStack spacing={4} align="stretch" width="full">
        <Flex justifyContent="space-between" alignContent="center" alignItems="center" width="full">
          <Text fontSize="16" fontWeight={400} mb={1}>
            QuickLink API Integration
          </Text>
          <CopyQuickLinkCreationCurl workflowId={workflowId} />
        </Flex>
        <Flex justify="space-between" mb={4} width="full">
          <Text fontSize="12" fontWeight="300">
            Sample cURL:
          </Text>
        </Flex>
        <CurlCommandDisplay />
      </VStack>
    </Box>
  </VStack>
);

const QuickLinkPreview: React.FC<QuickLinkPreviewProps> = ({
  quicklinkUrl,
  trimmedUrl,
  navigateToQuickLink,
  documentationLink,
}) => (
  <Flex direction="column" gridGap="4">
    <Flex direction="column" gridGap="1">
      <Heading fontSize="md" color="gray.800" fontWeight="500">
        Execute your Workflow through QuickLink
      </Heading>
      <Text fontSize="xs" fontWeight="300" color="gray.600" lineHeight="160%">
        Scan the QR code below or use the link to start your Workflow transaction!
      </Text>
    </Flex>

    <Box p="9" boxShadow="lg" borderRadius="lg" bg="white" border="1px" borderColor="gray.200" h="full">
      <VStack align="center" spacing="6">
        <Flex direction="column" alignItems="center" gridGap="3">
          <QrCode url={quicklinkUrl} />
          <Flex alignItems="center">
            <Link href={quicklinkUrl} color="blue.500" isExternal pb="1">
              <Text fontSize="xs" fontWeight="light">
                {trimmedUrl}
              </Text>
            </Link>
            <CopyButton text={quicklinkUrl} size="xs" />
          </Flex>
        </Flex>
        <Divider />
        <VStack spacing={1} textAlign="center">
          <Text fontSize="16" fontWeight="medium">
            How To Integrate This?
          </Text>
          <Text color="gray.600" fontSize="12" lineHeight="normal" fontWeight="light">
            For more information & steps about integration,
          </Text>
          <Button
            onClick={() => {
              window.open(documentationLink, '_blank');
            }}
            as="span"
            color="purple.100"
            cursor="pointer"
            lineHeight="normal"
            fontWeight="light"
            _hover={{
              color: 'blue.600',
              textDecoration: 'underline',
            }}
            transition="color 0.2s ease"
            fontSize="12"
            variant="link"
            rightIcon={<ExternalLinkIcon />}
          >
            Go to Documentation
          </Button>
        </VStack>
        <Divider />
        <VStack spacing={1} textAlign="center" w="full">
          <Text fontSize="16" fontWeight="medium">
            Manage your QuickLinks
          </Text>
          <Text color="gray.600" fontSize="12" lineHeight="normal" fontWeight="light">
            To manage and view all previously generated QuickLinks
          </Text>
          <Button
            onClick={navigateToQuickLink}
            as="span"
            color="purple.100"
            cursor="pointer"
            lineHeight="normal"
            fontWeight="light"
            _hover={{
              color: 'blue.600',
              textDecoration: 'underline',
            }}
            transition="color 0.2s ease"
            fontSize="12"
            variant="link"
            rightIcon={<ExternalLinkIcon />}
          >
            Go to QuickLink Manager
          </Button>
        </VStack>
      </VStack>
    </Box>
  </Flex>
);
export const CurlCommandDisplay: React.FC<CurlCommandDisplayProps> = (): JSX.Element => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'gray.100');

  return (
    <Box bg={bgColor} p={6} borderRadius="lg" fontSize="12" width="full" fontWeight="400" overflowX="auto">
      <VStack align="stretch" spacing={1} width="full">
        <Flex wrap="wrap" gap={2}>
          <Text color="blue.400">curl</Text>
          <Text color="blue.400">--</Text>
        </Flex>

        <Box pl={4}>
          <Flex wrap="wrap" gap={2}>
            <Text color="blue.400">location</Text>
            <Text color="blue.400">POST</Text>
            <Text color="blue.400" style={{ wordBreak: 'break-all' }}>
              &apos;https://api.bureau.id/workflows/quicklink&apos;
            </Text>
            <Text color="blue.400">\</Text>
          </Flex>
        </Box>

        <Box pl={4}>
          <Flex wrap="wrap" gap={2}>
            <Text color="blue.400">--header</Text>
            <Text color="blue.400">&apos;Authorization: Bearer </Text>
            <Text color="terracotta.500">&lt;YOUR TOKEN HERE&gt;</Text>
            <Text color="blue.400">&apos;</Text>
            <Text color="blue.400">\</Text>
          </Flex>
        </Box>

        <Box pl={4}>
          <Flex wrap="wrap" gap={2}>
            <Text color="blue.400">--header</Text>
            <Text color="blue.400">&apos;Content-Type: application/json&apos;</Text>
            <Text color="blue.400">\</Text>
          </Flex>
        </Box>

        <Box pl={4}>
          <Flex wrap="wrap" gap={2}>
            <Text color="blue.400">--data-raw</Text>
            <Text color="blue.400">{`'{'`}</Text>
          </Flex>
        </Box>

        <Box pl={8}>
          <Flex wrap="wrap" gap={2}>
            <Text color="blue.400">&quot;workflowId&quot;</Text>
            <Text color="blue.400">:</Text>
            <Text color="terracotta.500">&lt;WORKFLOW ID&gt;</Text>
            <Text color="blue.400">,</Text>
          </Flex>
        </Box>

        <Box pl={8}>
          <Flex wrap="wrap" gap={2}>
            <Text color="blue.400">&quot;name&quot;</Text>
            <Text color="blue.400">:</Text>
            <Text color="terracotta.500">&lt;Quicklink Name&gt;</Text>
            <Text color={textColor}>,</Text>
          </Flex>
        </Box>

        <Box pl={8}>
          <Flex wrap="wrap" gap={2}>
            <Text color="blue.400">&quot;transactionLimit&quot;</Text>
            <Text color={textColor}>:</Text>
            <Text color="terracotta.500">&lt;Transaction Limit&gt;</Text>
          </Flex>
        </Box>

        <Box pl={4}>
          <Text color="blue.400">{`}'`}</Text>
        </Box>
      </VStack>
    </Box>
  );
};

const QuickLink: React.FC<QuickLinkProps> = ({ workflowId }) => {
  const {
    generatedLinkID,
    isLoading,
    initialValues,
    validationSchema,
    handleSubmit,
    trimUrl,
    navigateToQuickLink,
    quicklinkUrl,
    documentationLink,
  } = useQuickLink(workflowId);

  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Grid templateColumns="1fr 1fr" h="full" width="full">
      <Flex h="full" borderRightWidth={1} borderColor={borderColor} direction="column" bg="white.100" p="4">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, formikHelpers) => handleSubmit(values, formikHelpers)}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form>
              <QuickLinkForm
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                isLoading={isLoading}
              />
            </Form>
          )}
        </Formik>
        <QuickLinkIntegration workflowId={workflowId} />
      </Flex>

      <Flex direction="column" p="4" h="full" w="full">
        {generatedLinkID ? (
          <QuickLinkPreview
            quicklinkUrl={quicklinkUrl}
            trimmedUrl={trimUrl(quicklinkUrl)}
            navigateToQuickLink={navigateToQuickLink}
            documentationLink={documentationLink}
          />
        ) : (
          <Flex h="full" w="full" alignItems="center" justifyContent="center" direction="column">
            <Box color="blue.700" px="1" py="4">
              <Barcode size="24" />
            </Box>
            <Text color="gray.500">Your QuickLink will be shown here</Text>
          </Flex>
        )}
      </Flex>
    </Grid>
  );
};

export default QuickLink;
