import React, { useEffect, useMemo, useState } from 'react';
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { colors } from '@/utils/colors';
import { CaretDown, CaretUp } from 'phosphor-react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import ReactJson from 'react-json-view';
import { useLocation } from 'react-router-dom';

import { CopyButton } from '@bureau/components/src';
import { bureauPlatformApi } from '@/utils/api';
import { useAuthDetails } from '@/queries/useAuth';
import { formatDate } from '@/utils/utils';
import { WorkflowAtom } from './states/workflowAtom';
import { InputsV2 } from './types';
import { useTryOut } from '../workflows/queries/useTryOut';
import WorkflowLoader from './components/WorkflowLoader';
import WorkflowErrorState from './components/WorkflowErrorState';
import { TryoutStateAtom } from './states/tryoutStateAtom';
import useFetchSupplierInputOutput from './queries/useFetchSupplierInputOutput';
import { getResponseTime } from './utils/helper';
import AwaitCapabilityCard from './tryout/components/AwaitCapabilityCard';
import TryoutResultHeader from './tryout/components/TryoutResultHeader';
import TryoutEmptyState from './tryout/components/TryoutEmptyState';

function TryoutConfig({ workflowId }: { workflowId: string }): React.ReactElement {
  const workflow = useRecoilValue(WorkflowAtom);
  const { pathname } = useLocation();
  const isTemplate = pathname.includes('templates');
  const getSupplierIds = (): string[] => {
    const supplierIds = [
      ...new Set(workflow.flatMap(workflowNode => workflowNode.nodes).flatMap(nodes => nodes.supplierId)),
    ] as string[];
    return supplierIds;
  };
  const { data: allUserInputs = { inputs: [], outputs: [] } } = useFetchSupplierInputOutput(getSupplierIds());

  const setTryoutState = useSetRecoilState(TryoutStateAtom);
  const resetTryoutState = useResetRecoilState(TryoutStateAtom);
  const { tryoutResponse, showTryOutResult } = useRecoilValue(TryoutStateAtom);

  const [isSomeCapabilityAsync, setIsSomeCapabilityAsync] = useState(false);
  const [initialTime, setInitialTime] = useState(Date.now());
  const [responseTime, setResponseTime] = useState('');
  const [showResponse, setShowResponse] = useState(false);

  useEffect(() => {
    const handleSetTransaction = (event: MessageEvent): void => {
      if (event.data) {
        try {
          const eventData = JSON.parse(event.data);

          if ('transactionId' in eventData) {
            const request = {
              data: {},
              transactionId: eventData.transactionId,
              ...(isTemplate ? { templateId: workflowId } : { workflowId }),
            };
            setInitialTime(Date.now());
            tryOutWorkflow(request);
          }
          // eslint-disable-next-line no-empty
        } catch {}
      }
    };

    window.addEventListener('message', handleSetTransaction);

    // resetTryout result data on tryout config modal close
    return () => {
      resetTryoutState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutateAsync: tryOutWorkflow, isLoading: isTransactionLoading, isError } = useTryOut({
    onSuccess: response => {
      const isAsyncCapabilityAwaited = response.outcome === 'AWAITING';

      setTryoutState({ tryoutResponse: response, showTryOutResult: true });
      setIsSomeCapabilityAsync(isAsyncCapabilityAwaited);
      setResponseTime(isAsyncCapabilityAwaited ? '-' : getResponseTime(initialTime, Date.now()));
      setShowResponse(true);
    },
  });

  // service inputs which are mapped as user input
  const serviceInputs = useMemo(
    () =>
      workflow
        .flatMap(column => column.nodes)
        .flatMap(node => node.inputs)
        .filter(input => input?.location?.includes('input')), // mapped as user input
    [workflow],
  );

  // key of mapped user inputs
  const serviceInputsKey = useMemo(() => serviceInputs.map(input => input?.location?.split('.')[2]), [serviceInputs]);

  const userInputs = useMemo(
    () => allUserInputs.inputs.filter(inputParams => serviceInputsKey.includes(inputParams.key)) as InputsV2[],
    [allUserInputs, serviceInputsKey],
  );
  const { data: authDetails } = useAuthDetails();

  const options = {
    workflowId,
    type: 'INIT_DATA',
    isTemplate,
    authMetadata: authDetails,
    env: process.env.NODE_ENV,
    baseUrl: authDetails?.region === 'us' ? process.env.REACT_APP_US_ENDPOINT : process.env.REACT_APP_BASE_URL,
    webt: bureauPlatformApi.defaults.headers.common.Authorization,
    orgId: bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID'],
  };

  const encodedOptions = window.btoa(JSON.stringify(options));

  return (
    <>
      <Grid gridTemplateColumns="1fr 1fr" h="full">
        {/* LEFT SIDE SECTION - FORM */}
        <Flex
          h="full"
          borderRightWidth={1}
          borderColor="gray.200"
          direction="column"
          justifyContent="space-between"
          bg="white.100"
          py="6"
        >
          <iframe
            style={{
              height: '100%',
              width: '100%',
              borderRadius: 16,
              maxWidth: 303,
              margin: 'auto',
              border: '1px solid',
              borderColor: colors.blue[300],
              // aspectRatio: '303/648',
            }}
            title="hostedFlow SDK"
            src={`${process.env.REACT_APP_TRYOUT_SDK_URL}/?options=${encodedOptions}`}
            id="tryout-preview"
            allow="camera *;microphone *;fullscreen;accelerometer;gyroscope;magnetometer;"
            allowFullScreen
          />
        </Flex>

        {/* RIGHT SIDE SECTION - RESULT */}
        <Flex p="4" w="full" overflow="auto" justifyContent="center" alignItems="center">
          {/* EMPTY STATE */}
          {!(isTransactionLoading || isError || showTryOutResult) && <TryoutEmptyState />}

          {/* LOADER */}
          {isTransactionLoading && !isSomeCapabilityAsync && (
            <WorkflowLoader text="Hold tight! it will take a few seconds to process" textStyles={{ w: '52' }} />
          )}

          {/* ERROR STATE */}
          {isError && !isSomeCapabilityAsync && (
            <WorkflowErrorState
              errorText="It seems there is a technical issue that is preventing data from loading."
              styles={{ w: '64' }}
            />
          )}

          {/* RESPONSE */}
          {showTryOutResult && (
            <Flex w="full" direction="column" alignSelf="flex-start" gridGap="6">
              <TryoutResultHeader tryoutResponse={tryoutResponse} />

              {isSomeCapabilityAsync && (
                <AwaitCapabilityCard
                  // tryoutResponse={tryoutResponse}
                  isTransactionLoading={isTransactionLoading}
                  onRefreshClick={() => {
                    const request = {
                      data: {},
                      transactionId: tryoutResponse.transactionId,
                      ...(isTemplate ? { templateId: workflowId } : { workflowId }),
                    };
                    tryOutWorkflow(request);
                  }}
                />
              )}

              {/* User Raw Inputs */}
              <Grid gridTemplateColumns="1fr 1fr" gridGap="6" overflowY="auto" maxH="calc(50vh - 250px)">
                {userInputs?.map(input =>
                  tryoutResponse?.transactionRawInput[input.key] ? (
                    <GridItem key={input.key}>
                      <Text fontSize="sm" fontWeight="light" color="gray.600">
                        {input.displayName}
                      </Text>

                      <Text
                        fontSize="sm"
                        color="gray.700"
                        maxW="40"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                      >
                        {input.key === 'dob'
                          ? formatDate(Number(tryoutResponse?.transactionRawInput[input.key]))
                          : tryoutResponse?.transactionRawInput[input.key].toString()}
                      </Text>
                    </GridItem>
                  ) : null,
                )}
                <GridItem>
                  <Text fontSize="sm" fontWeight="light" color="gray.600">
                    Response Time
                  </Text>
                  <Text fontSize="sm" color="gray.700">
                    {isSomeCapabilityAsync ? '-' : responseTime}
                  </Text>
                </GridItem>
              </Grid>

              {/* Final Response */}
              <Box minH="50px" borderRadius="lg" borderWidth={1} borderColor="gray.300">
                <Flex
                  justifyContent="space-between"
                  px={4}
                  py={3}
                  borderBottomWidth={showResponse ? 1 : 0}
                  cursor="pointer"
                  onClick={() => setShowResponse(!showResponse)}
                >
                  <Text fontSize="sm" fontWeight="light" color="gray.600">
                    Final Response
                  </Text>
                  <Flex
                    alignItems="center"
                    h={6}
                    color="blue.600"
                    fontWeight="normal"
                    fontSize="xs"
                    cursor="pointer"
                    gridGap="4"
                  >
                    <CopyButton color="gray.600" text={JSON.stringify(tryoutResponse)} />
                    {showResponse ? (
                      <CaretUp size={20} color={colors.gray[600]} />
                    ) : (
                      <CaretDown size={20} color={colors.gray[600]} />
                    )}
                  </Flex>
                </Flex>

                {showResponse && (
                  <Box pl="4" maxH="calc(50vh - 150px)" overflowY="auto">
                    {isTransactionLoading && (
                      <Flex h="calc(50vh - 150px)" justifyContent="center">
                        <WorkflowLoader
                          text="Hold tight! It will take a few seconds to process"
                          textStyles={{ w: '52' }}
                        />
                      </Flex>
                    )}

                    {isError && (
                      <Flex h="calc(50vh - 150px)" justifyContent="center">
                        <WorkflowErrorState
                          errorText="It seems there is a technical issue that is preventing data from loading."
                          styles={{ w: '64' }}
                        />
                      </Flex>
                    )}

                    {!(isTransactionLoading || isError) && (
                      <ReactJson
                        src={tryoutResponse}
                        name={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        enableClipboard={false}
                        quotesOnKeys={false}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                        // @ts-ignore
                        displayArrayKey={false}
                        // sortKeys
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Flex>
          )}
        </Flex>
      </Grid>
    </>
  );
}

export default TryoutConfig;
