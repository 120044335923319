import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Box, Text, useToast } from '@chakra-ui/react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { AxiosError } from 'axios';
import exportFromJSON from 'export-from-json';
import { useQueryClient } from 'react-query';

import AddEditWorkflowModal from '@/screens/workflowv2/components/AddEditWorkflowModal';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal';
import useDebounce from '@/hooks/useDebounce';
import { CustomErrorCode, UserAction } from '@/types/common';
import { selectAllOption } from '@/constants/constants';
import { Parameter } from '../workflows/components/try-out-modal/types';
import { ListData, ToastMessage, WorkflowFilterField } from '../workflows/types';
import { useInfiniteWorkflowList } from '../workflows/queries/useWorkflowList';
import useGetWorkflow from '../workflows/workflow-creation/queries/useGetWorkflow';
import useUpdateWorkflow from '../workflows/workflow-creation/queries/useUpdateWorkflow';
import useDeleteWorkflow from '../workflows/workflow-creation/queries/useDeleteWorkflow';
import WorkFlowListContent from './components/WorkflowListContent';
import WorkflowSearchFilter from './components/WorkflowSearchFilter';
import AddDuplicateWorkflowModal from './components/AddDuplicateWorkflowModal';
import { ModifyWorkflowDetails } from './types';
import { refetchDelay, outcomeOptions } from './constants';
import { WorkflowAtom } from './states/workflowAtom';
import { BranchAtom } from './states/branchAtom';
import { ConditionsAtom } from './states/conditionsAtom';
import { EndnodeAtom } from './states/endnodeAtom';
import { SelectedNodeAtom } from './states/selectedNodeAtom';
import { WorkflowNameAtom } from './states/workflowName';

export const initialWorkflowDetails = {
  name: '',
  workflowId: '',
  active: false,
};

export const initialTryOutDetails: Parameter[] = [{ parameter: '', value: '', type: '' }];

export const initialFilterField = {
  outcome: [...outcomeOptions.map(item => item.value), selectAllOption.value],
  createdAt: [],
};

export const WorkflowList = ({
  workflowType = 'workflows',
}: {
  workflowType?: 'templates' | 'workflows';
}): React.ReactElement => {
  const history = useHistory();
  const toast = useToast();

  const queryClient = useQueryClient();
  const resetWorkflow = useResetRecoilState(WorkflowAtom);
  const resetBranch = useResetRecoilState(BranchAtom);
  const resetConditions = useResetRecoilState(ConditionsAtom);
  const resetEndNode = useResetRecoilState(EndnodeAtom);
  const resetSelectedNodeItem = useResetRecoilState(SelectedNodeAtom);
  const resetWorkflowName = useResetRecoilState(WorkflowNameAtom);
  const setWorkflowName = useSetRecoilState(WorkflowNameAtom);
  const [filterFields, setFilterFields] = useState<WorkflowFilterField>(initialFilterField);
  const [searchTerm, setSearchTerm] = useState('');
  const [nameExistsError, setNameExistsError] = useState(false);
  const [workflowDetails, setWorkflowDetails] = useState<ModifyWorkflowDetails>(initialWorkflowDetails);
  const [isExportButtonClicked, setIsExportButtonClicked] = useState(false);
  const [offset, setOffset] = useState(0);
  const [selectedAction, setSelectedAction] = useState('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useLayoutEffect(() => {
    document.title = 'Workflows';
  }, []);

  const searchItem = useDebounce(searchTerm);

  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } = useInfiniteWorkflowList(
    workflowType,
    filterFields,
    searchItem,
    offset,
  );

  const { data: workflowObject, refetch: refetchWorkflowDetails, isFetching: isDetailsFetching } = useGetWorkflow(
    workflowDetails.workflowId,
  );

  const { listData } = data ? (data as ListData) : { listData: [] };
  const workflowList =
    workflowType === 'templates'
      ? listData.filter(item => !item.isDemo)
      : listData.filter(workflowData => workflowData.releaseType === 'v2');

  const { mutateAsync: updateWorkflow } = useUpdateWorkflow({
    onSuccess: () => handleSuccess(ToastMessage.WORKFLOW_UPDATED_MESSAGE),
    onError: updateError => handleModifyWorkflowError(updateError),
  });

  const handleModifyWorkflowError = (updateError: AxiosError): void => {
    if (updateError?.response?.status === CustomErrorCode.RECORD_EXISTS) {
      setNameExistsError(true);
      setIsButtonLoading(false);
    } else {
      handleErrorToast(updateError);
    }
    setIsButtonLoading(false);
  };

  const { mutateAsync: deleteWorkflow } = useDeleteWorkflow({
    onSuccess: () => {
      handleSuccess(ToastMessage.WORKFLOW_DELETED_MESSAGE);
    },
    onError: deleteError => {
      setIsButtonLoading(false);
      setSelectedAction('');
      handleErrorToast(deleteError);
    },
  });

  const handleSuccess = (toastMessage: string): void => {
    setTimeout(() => {
      setIsButtonLoading(false);
      setSelectedAction('');
      handleSuccessToast(toastMessage);
      refetch();
    }, refetchDelay);
  };

  const handleSuccessToast = (description: string): void => {
    toast({
      description,
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleErrorToast = (mutateError: AxiosError): void => {
    toast({
      description: mutateError?.response?.data?.error?.message || ToastMessage.ERROR_MESSAGE,
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  };

  const onDuplicate = (details: ModifyWorkflowDetails): void => {
    resetWorkflow();
    resetBranch();
    resetConditions();
    resetEndNode();
    resetSelectedNodeItem();
    resetWorkflowName();
    queryClient.removeQueries({ queryKey: 'workflowById-v2' });
    setWorkflowName(details.name || '');
    history.push(`/workflows/${details.workflowId}/duplicate`);
  };

  const onEditClose = (details: ModifyWorkflowDetails): void => {
    updateWorkflow({ id: details?.workflowId, workflow: { name: details?.name } });
    setIsButtonLoading(true);
  };

  const onActionPopupCancel = (): void => {
    setSelectedAction('');
    setWorkflowDetails(initialWorkflowDetails);
  };

  const onDelete = (): void => {
    if (workflowDetails?.workflowId) {
      deleteWorkflow({ id: workflowDetails?.workflowId });
      setIsButtonLoading(true);
    }
  };

  useEffect(() => {
    if (workflowObject?.workflowId && !isDetailsFetching && isExportButtonClicked) {
      exportFromJSON({
        data: workflowObject?.workflowObject,
        fileName: workflowObject?.name,
        exportType: exportFromJSON?.types.json,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowObject, isDetailsFetching, isExportButtonClicked]);

  useEffect(() => {
    setOffset(0);
  }, [filterFields, searchItem]);

  useEffect(() => {
    if (selectedAction === UserAction.EXPORT && workflowDetails.workflowId) {
      setIsExportButtonClicked(true);
      refetchWorkflowDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAction, workflowDetails]);

  return (
    <Flex direction="column" h="full" w="full" gridGap="4">
      <WorkflowSearchFilter
        filterFields={filterFields}
        setFilterFields={setFilterFields}
        setSearchTerm={setSearchTerm}
        workflowType={workflowType}
      />

      <Box h="full" overflowY="auto">
        <WorkFlowListContent
          workflowType={workflowType}
          filterFields={filterFields}
          data={workflowList}
          isFetching={isFetching}
          isError={isError}
          refetch={refetch}
          searchTerm={searchItem}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          setSelectedAction={setSelectedAction}
          setWorkflowDetails={setWorkflowDetails}
        />
      </Box>
      {selectedAction === UserAction.EDIT && (
        <AddEditWorkflowModal
          details={workflowDetails}
          onClose={onEditClose}
          onCancel={onActionPopupCancel}
          editFromList
          nameExistsError={nameExistsError}
          setNameExistsError={setNameExistsError}
          isLoading={isButtonLoading}
        />
      )}
      {selectedAction === UserAction.DELETE && (
        <ConfirmationModal
          headerText="Delete Workflow"
          bodyText={
            <Box fontSize="sm" display="flex">
              Do you really want to delete{' '}
              <Text ml={1} color="blue.500" fontWeight={600} textDecoration="underline">
                {workflowDetails?.name}?
              </Text>
            </Box>
          }
          isLoading={isButtonLoading}
          onClose={onDelete}
          onCancel={onActionPopupCancel}
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          primaryButtonBg="red"
        />
      )}
      {selectedAction === UserAction.DUPLICATE && (
        <AddDuplicateWorkflowModal
          details={workflowDetails}
          onClose={onDuplicate}
          onCancel={onActionPopupCancel}
          nameExistsError={nameExistsError}
          setNameExistsError={setNameExistsError}
        />
      )}
    </Flex>
  );
};

export default WorkflowList;
